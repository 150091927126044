// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ==========================================================================
// Forms - Date Picker
// ==========================================================================

import React from "react"
import PropTypes from "prop-types"
import MaskedInput from "react-text-mask"
import ReactDatePicker from "react-datepicker"
import { createAutoCorrectedDatePipe } from "text-mask-addons"

import "react-datepicker/dist/react-datepicker.css"

export const HookDatePicker = ({
  onChange,
  name,
  placeholder,
  wrapperClassName,
  className,
  open,
  touched,
  errors,
  value,
  maxDate,
  customInput,
  format,
}) => {
  return (
    <>
      <ReactDatePicker
        showYearDropdown
        name={name}
        className={`form-control ${className}`}
        placeholderText={placeholder}
        wrapperClassName={wrapperClassName}
        selected={value}
        onChange={(date) => onChange(date)}
        dateFormat={format}
        // onChange={(date) => alert(JSON.stringify(date))}
        maxDate={maxDate}
        scrollableYearDropdown
        customInput={customInput}
        open={open}
      />
      {touched && errors && touched[name] && errors[name] && (
        <div className="validation-error">{errors[name]}</div>
      )}
    </>
  )
}

HookDatePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool,
}

HookDatePicker.defaultProps = {
  name: "",
  placeholder: "",
  wrapperClassName: "",
  className: "",
  format: "dd-MM-yyyy",
  open: false,
  customInput: (
    <MaskedInput
      pipe={createAutoCorrectedDatePipe("dd-mm-yyyy HH:MM")}
      mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      keepCharPositions={true}
      guide={true}
      type="tel"
    />
  ),
}
