import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

export const HookForm = ({
  id,
  name,
  children,
  onSubmit,
  className,
  defaultValues,
  mode = "onChange",
}) => {
  const [values, setValues] = useState({})
  const {
    watch,
    control,
    trigger,
    setValue,
    register,
    setError,
    getValues,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm({ mode, defaultValues })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setValues((prev) => ({ ...prev, ...value }))
    })
    if (defaultValues) setValues(defaultValues)
    return () => subscription.unsubscribe()
  }, [getValues, watch, defaultValues])

  return (
    <form
      id={id}
      className={className}
      name={name}
      onSubmit={handleSubmit(onSubmit)}
    >
      {typeof children === "function"
        ? children({
            watch,
            values,
            errors,
            isValid,
            control,
            trigger,
            setValue,
            setError,
            register,
            getValues,
            setFocus,
            defaultValues,
          })
        : React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              watch,
              values,
              errors,
              isValid,
              control,
              trigger,
              setValue,
              setError,
              register,
              getValues,
              setFocus,
              defaultValues,
            })
          })}
    </form>
  )
}
