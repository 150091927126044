// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ==========================================================================
// Forms - Input
// ==========================================================================

import React, { useRef } from "react"
import PropTypes from "prop-types"

import ReactTooltip from "react-tooltip"

export const HookInput = ({
  id,
  name,
  variant,
  theme,
  as,
  type,
  label,
  placeholder,
  value,
  formText,
  onChange,
  onBlur,
  errors,
  touched,
  setValue,
  inputMode,
  rows,
  iconRight,
  iconLeft,
  floatingLabel,
  className,
  wrapperClassName,
  labelClassName,
  inputClassName,
  children,
  disabled,
  hideValidationText,
  register,
  required,
  textRight,
  validation,
  tooltip,
  forward,
  maxLength,
  pattern,
  min,
  focus,
  setFocus,
  ...props
}) => {
  const InputRef = useRef(null)

  const fieldVariant = () => {
    if (variant === "underline") {
      return "field-underline"
    } else {
      return "field-basic"
    }
  }

  React.useEffect(() => {
    if (focus) {
      setFocus(focus)
    }
  }, [])

  return (
    <div
      className={`field ${fieldVariant()}
        ${theme}
        ${floatingLabel ? "field-floating" : ""}
        ${errors && errors[name] ? "is-invalid" : ""}
        ${className} `}
    >
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}

      {as === "input" && (
        <div
          className={`form-control-wrapper
          ${iconRight ? "ic-right" : ""}
          ${iconLeft ? "ic-left" : ""}
          ${wrapperClassName}`}
        >
          <input
            ref={InputRef}
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            className={`form-control ${inputClassName}`}
            value={value}
            onBlur={onBlur}
            pattern={pattern}
            min={min}
            inputMode={inputMode}
            // onChange={e => onChange(name, e.target.value)}
            disabled={disabled}
            maxLength={maxLength}
            onWheel={(e) => {
              if (type === "number") {
                e.target.blur()
              }
            }}
            {...register(name, {
              required: { value: required, message: "Field cannot be empty" },
              ...validation,
              // onChange: e => {
              //     if (onChange) {
              //         onChange(name, e.target.value, setValue)
              //     }
              // },
            })}
          />
          {floatingLabel && (
            <label className={labelClassName} htmlFor={id}>
              {label}
            </label>
          )}
          <i className="el-underline" />
          {children}
          {textRight && <p className="input-right text-muted">{textRight}</p>}
          {tooltip && (
            <>
              <i data-tip={tooltip} className="tooltip-icon" />
              <ReactTooltip place="bottom" backgroundColor="#a1a1a1" />
            </>
          )}
        </div>
      )}

      {as === "textarea" && (
        <div
          className={`form-control-wrapper
                            ${iconRight ? "ic-right" : ""}
                            ${iconLeft ? "ic-left" : ""}
                            ${wrapperClassName}`}
        >
          <textarea
            id={id}
            name={name}
            placeholder={placeholder}
            className={`form-control ${inputClassName}`}
            value={value}
            onBlur={onBlur}
            // onChange={e => onChange(name, e.target.value)}
            disabled={disabled}
            rows={rows}
            inputMode={inputMode}
            type={type}
            maxLength={maxLength}
            {...register(name, {
              required: {
                value: required,
                message: "Field ini tidak boleh kosong",
              },
              ...validation,
              // onChange: e => {
              //     if (onChange) {
              //         onChange(name, e.target.value)
              //     }
              // },
            })}
          />
          {floatingLabel && (
            <label className={labelClassName} htmlFor={id}>
              {label}
            </label>
          )}
          <i className="el-underline" />
          {children}
        </div>
      )}
      {!hideValidationText && (
        <>
          {errors && errors[name] && (
            <div className="invalid-feedback">{errors[name].message}</div>
          )}
        </>
      )}
      {formText && <div className="form-text">{formText}</div>}
    </div>
  )
}

HookInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  variant: PropTypes.oneOf(["basic", "underline"]),
  theme: PropTypes.oneOf(["light", "dark"]),
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.string,
  value: PropTypes.string,
  floatingLabel: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.any,
}

HookInput.defaultProps = {
  id: "",
  type: "text",
  variant: "basic",
  theme: "light",
  label: null,
  as: "input",
  placeholder: "Enter text",
  required: false,
  className: "",
  labelClassName: "",
  inputClassName: "",
  wrapperClassName: "",
  hideValidationText: false,
}
